import { ShopProduct } from '@/modules/shop/types';
import { ListProduct } from '@/modules/product/types';

type ProductArg = Omit<ShopProduct, 'category_id'> & {
  category_id?: number;
};

export function transformShopProduct(product: ProductArg): ListProduct {
  const {
    has_video,
    variant_set_id,
    category_id,
    brand_id,
    is_boosted,
    pricing: {
      currency_name: currencyName,
      original_price: { total_price: priceAmount },
      discounted_price: { total_price: discountedPriceAmount } = {},
    },
    ...rest
  } = product;

  return {
    ...rest,
    hasVideo: has_video,
    isBoosted: is_boosted,
    price: {
      currencyName,
      priceAmount,
      discountedPriceAmount,
    },
    variantSetId: variant_set_id,
    categoryId: category_id,
    brandId: brand_id,
  };
}
