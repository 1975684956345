import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  IconComponent?: (props: Record<string, unknown>) => React.ReactElement;
};

export function ShopEmptyState({
  children,
  IconComponent,
  className = '',
}: Props) {
  return (
    <div className={clsx(styles.container, className)}>
      {IconComponent && <IconComponent size={30} className={styles.icon} />}
      {children}
    </div>
  );
}
