import { useQuery } from '@tanstack/react-query';

import { LEGACY_PRODUCT_BY_ID } from '@/modules/ReactQuery/cacheKeys.ts';
import { getLegacyProductById } from '@/modules/product/api.ts';
import { useSessionId } from '@/modules/storage/useSessionId.ts';

export function useLegacyProductById(productId?: string) {
  const sessionId = useSessionId();

  return useQuery({
    queryKey: [LEGACY_PRODUCT_BY_ID, productId],
    queryFn: async () => {
      const res = await getLegacyProductById({
        sessionId,
        productId: productId as string,
      });
      return res?.data;
    },
    enabled: Boolean(productId),
  });
}
