import clsx from 'clsx';
import React from 'react';

import { SortableItemsContainer } from '@/components/SortableItemsContainer';
import styles from '@/app/[locale]/[username]/(shop)/components/ProductList/styles.module.css';
import { SortableItemWrapper } from '@/components/SortableItemWrapper';
import { ProductListCard } from '@/app/[locale]/[username]/(shop)/components/ProductList/ProductListCard';
import { ListProduct } from '@/modules/product/types';
import { useRearrangeProducts } from '@/modules/shop/hooks/useRearrangeProducts';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ShopByUsernameResponse } from '@/modules/shop/types';

type Props = {
  initialProducts: ListProduct[];
  seller?: ShopByUsernameResponse;
};

export function RearrangeProductList({ initialProducts, seller }: Props) {
  const [_, currentUser] = useCurrentUser();
  const userId = currentUser?.id;
  const {
    isRearrangeModeActive,
    isRearrangeModeSupported,
    handleRearrangeDragEnd,
    rearrangeableProductList,
  } = useRearrangeProducts(userId, initialProducts);

  const products = isRearrangeModeSupported
    ? rearrangeableProductList
    : initialProducts;

  return (
    <SortableItemsContainer
      onDragEnd={handleRearrangeDragEnd}
      items={products.map((product) => product.id)}
    >
      {products.map((product) => (
        <li key={product.id} className={styles.listItem}>
          <SortableItemWrapper
            id={product.id}
            disabled={!isRearrangeModeActive}
            className={clsx({
              [styles.animatedWrapper]: isRearrangeModeActive,
            })}
          >
            <ProductListCard
              isRearrangeModeActive={isRearrangeModeActive}
              product={product}
              seller={seller}
            />
          </SortableItemWrapper>
        </li>
      ))}
    </SortableItemsContainer>
  );
}
