import {
  DepopShippingStatus as DepopParcelShippingStatus,
  ListParcel,
  ManualShippingStatus as ManualParcelShippingStatus,
  Parcel,
  ParcelBase,
} from '@/modules/parcels/types';
import { Feedback } from '@/modules/feedback/types';
import { CurrencyCode } from '@/modules/currencies/constants';

export enum Role {
  Buyer = 'buyer',
  Seller = 'seller',
}

export type ShippingAddress = {
  name: string;
  address: string;
  address2?: string;
  city: string;
  state?: string;
  postcode?: string;
  country: string;
};

export enum ReceiptStatus {
  AwaitingShipping = 'awaitingShipping',
  Shipped = 'shipped',
  Delivered = 'delivered',
  ShippingLabelExpired = 'shippingLabelExpired',
  Unknown = 'unknown',
  Failed = 'failed',
  InTransit = 'inTransit',
  Returned = 'returned',
  Cancelled = 'cancelled',
}

export enum ShippingStatus {
  Shipped = 'shipped',
  AwaitingShipping = 'awaitingShipping',
  All = '',
}

export enum SoldItemsShippingStatus {
  All = '',
  ToShip = 'awaitingShipping',
  InTransit = 'shipped',
  Cancelled = 'cancelled',
  Completed = 'complete',
}

export enum PaymentProvider {
  PayPal = 'paypal',
  Wallet = 'wallet',
  Stripe = 'stripe',
  Klarna = 'klarna',
}

export enum ListReceiptShippingProviderType {
  Depop = 'depop',
  Manual = 'manual',
}

export type RefundDetails = {
  isRefundable: boolean;
  refundDate?: string;
  buyerRefundAmount?: string;
};

type ReceiptBase<U, T> = {
  purchaseId: number;
  transactionId: number;
  buyer: U;
  seller: U;
  shippingAddress: ShippingAddress;
  shipFromAddressId?: number;
  soldTimestamp: string;
  shippingStatus: ShippingStatus;
  cancellationDetails?: {
    reason: string;
  };
} & T;

export declare namespace ListReceipt {
  type Item = {
    pictureUrl: string;
    productId: number;
    description: string;
  };

  type User = {
    id: number;
    username: string;
  };

  type Receipt = ReceiptBase<
    User,
    {
      buyerPaidAmount: string;
      buyerPaymentProvider: PaymentProvider;
      buyerRefundAmount?: string;
      currency: CurrencyCode;
      hasBoostingFee?: boolean;
      isBundle?: boolean;
      items: Item[];
      lineItems?: { id: number; repopAvailable: boolean }[];
      parcels: ListParcel.AnyParcel[];
      seller: User;
      sellerPaidAmount?: string;
    }
  >;

  type ShippingProviderBase = ParcelBase<
    ManualParcelShippingStatus | DepopParcelShippingStatus,
    {
      type: ListReceiptShippingProviderType;
      providerName?: string;
    }
  >;

  export type ShippingProvider = Omit<ShippingProviderBase, 'shippingProvider'>;

  type TransformedReceipt = Pick<
    Receipt,
    | 'purchaseId'
    | 'soldTimestamp'
    | 'shippingStatus'
    | 'parcels'
    | 'hasBoostingFee'
    | 'isBundle'
    | 'lineItems'
    | 'cancellationDetails'
    | 'buyerRefundAmount'
  > & {
    urls: Array<string>;
    productIds: Array<number>;
    itemCount: number;
    buyerInfo: {
      id: number;
      username: string;
      location: {
        city: string;
        postcode: string;
        formattedAddress: string;
      };
    };
    sellerInfo: {
      id: number;
      username: string;
    };
    paymentInfo: {
      buyerPaidAmount: string;
      buyerPaidAmountPlain: string;
      provider: PaymentProvider;
      sellerPaidAmount?: string;
    };
    shippingProviders: ShippingProvider[];
    depopShipping?: ShippingProvider;
    manualShipping?: ShippingProvider;
  };
}

export enum PayoutDescriptionStatus {
  Paid = 'paid',
  Pending = 'pending',
  InTransit = 'inTransit',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

export declare namespace Receipt {
  type User = ListReceipt.User & {
    firstName: string;
    lastName: string;
    pictureUrl?: string;
    isActive: boolean;
  };

  type Item = ListReceipt.Item & {
    slug?: string;
    brand?: string;
    itemAmount: string;
    undiscountedAmount?: string;
    itemCurrency: CurrencyCode;
    description: string;
    size?: string;
    hasDepopShipping: boolean;
    boostingFee?: FeeAmount;
  };

  type ReceiptFeedback = {
    leftByBuyer?: Feedback;
    leftBySeller?: Feedback;
  };

  type PayoutDescription = {
    id: number;
    status: PayoutDescriptionStatus;
    arrivalDate: string;
    amount: string;
    currency: CurrencyCode;
  };

  type PaymentDetails = {
    currency: CurrencyCode;
    itemsAmount: string;
    undiscountedItemsAmount?: string;
    paymentFee?: string;
    depopFee?: string;
    boostingFee?: FeeAmount;
    buyerPaidAmount: string;
    sellerReceivedAmount: string;
    payoutDescription?: PayoutDescription;
    payoutEstimatedDate?: string;
    buyerPaymentProvider: PaymentProvider;
    shippingAmount: string;
    buyerShippingAmount: string;
    sellerShippingAmount: string;
    taxSummary?: {
      totalTaxAmount: string;
      shipToLocation: string;
    };
    buyerFee: FeeAmount;
    sellerFee: FeeAmount;
  };

  type FeeAmount = {
    amount: string;
    originalAmount?: string;
  };

  type LineItem = {
    id: number;
    repopAvailable: boolean;
  };

  type Receipt = ReceiptBase<
    User,
    {
      receiptStatus: ReceiptStatus;
      refundDetails?: RefundDetails;
      items: Item[];
      parcels: Parcel.AnyParcel[];
      feedback?: ReceiptFeedback;
      paymentDetails: PaymentDetails;
      isBundle?: boolean;
      lineItems?: LineItem[];
    }
  >;
}

export type Meta = {
  cursor: string | null;
  end: boolean;
};

export enum ReceiptType {
  Buyer = 'buyer',
  Seller = 'seller',
}

export type GetReceiptsResponse = {
  meta: Meta;
  receipts: ListReceipt.Receipt[];
};

export type PaymentDetailLine = {
  label: string;
  originalPrice?: string;
  price: string;
};
