import { useParams } from 'next/navigation';

import { UrlParams } from '../types';

import { useCurrentUser } from '@/modules/user/useCurrentUser.ts';

export function useIsViewingOwnShop() {
  const { username } = useParams<UrlParams>();
  const [, currentUser] = useCurrentUser();

  return currentUser?.username === username;
}
