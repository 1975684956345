import { ListParcel, Parcel } from '@/modules/parcels/types.ts';

export function isListDepopShipping(
  parcel: ListParcel.DepopShipping | ListParcel.ManualShipping
): parcel is ListParcel.DepopShipping {
  if (!parcel.shippingProvider) {
    return false;
  }
  return parcel.shippingProvider.toUpperCase() !== 'MANUAL';
}

export function hasDepopShipping(parcel: Parcel.AnyParcel) {
  return (
    'depopShippingProvider' in parcel ||
    'tracking' in parcel ||
    isListDepopShipping(parcel)
  );
}

export function getFirstDepopShippingParcel(parcels: Parcel.AnyParcel[]) {
  return parcels.find((p) => hasDepopShipping(p));
}

export function getFirstManualShippingParcel(parcels: Parcel.AnyParcel[]) {
  return parcels.find((p) => !hasDepopShipping(p));
}
