import React from 'react';
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

type SortableProductListProps = {
  children: React.ReactNode;
  onDragEnd: (event: DragEndEvent) => void;
  items: number[];
};

function SortableItemsContainer({
  children,
  onDragEnd,
  items,
}: SortableProductListProps) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd}>
      <SortableContext items={items}>{children}</SortableContext>
    </DndContext>
  );
}

export { SortableItemsContainer };
