import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';
import { Tooltip } from '@depop/web-ui-kit/Tooltip';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { FlashBoldIcon } from '@depop/web-ui-kit/Icons/FlashBoldIcon';

import styles from './styles.module.css';

import { ProductCard } from '@/components/ProductCard';
import { Price } from '@/components/Price';
import { ListProduct, Preview } from '@/modules/product/types';
import { isSold } from '@/modules/product/helpers/isSold';
import { BOOSTED_TEXT_ANY_LOCALE } from '@/constants/boostedListings';
import { RootState } from '@/modules/redux/stores/storeClient';
import { LikeButton } from '@/app/[locale]/(browse)/components/BrowseProductCard/LikeButton.tsx';

export type Props = {
  id: number;
  slug: string;
  preview: Preview;
  price: ListProduct['price'];
  showBoostedTag?: boolean;
  likeButton?: { isVisible: boolean; isLiked: boolean };
  status: string;
  sellerUserName?: string;
  handleClick?: () => void;
  isRearrangeModeActive?: boolean;
  HoverOverlay?: React.FC;
};

// If this written inline as part of the Wrapper prop, this will cause re-renders after dragend
function RearrangeableProductCardWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return <span>{children}</span>;
}

export function ShopProductCard({
  id,
  slug,
  preview,
  showBoostedTag,
  likeButton,
  status,
  price,
  sellerUserName,
  handleClick,
  HoverOverlay,
}: Props) {
  const t = useTranslations('common');
  const isProductSold = isSold(status);
  const { isRearrangeModeActive } = useSelector(
    (state: RootState) => state.shop
  );
  const showProductAttributes = !isRearrangeModeActive;

  return (
    <ProductCard
      productHref={slug}
      preview={preview}
      handleClick={handleClick}
      sellerUserName={sellerUserName}
      HoverOverlay={HoverOverlay}
      Wrapper={
        isRearrangeModeActive ? RearrangeableProductCardWrapper : undefined
      }
      ProductAttributes={() =>
        showProductAttributes ? (
          <div className={styles.productAttributesContainer}>
            <Price
              currencyCode={price.currencyName}
              price={price.priceAmount}
              discountedPrice={price.discountedPriceAmount}
              inPreview
            />
          </div>
        ) : null
      }
      Overlay={() => (
        <>
          {likeButton?.isVisible && (
            <div className={styles.gradientOverlay}>
              <LikeButton
                productId={id}
                initialLikedState={likeButton.isLiked}
              />
            </div>
          )}
          {isProductSold && (
            <div className={styles.gradientOverlay}>
              <H3 className={styles.soldText} as="p">
                {t('SoldSingular')}
              </H3>
            </div>
          )}
          {showBoostedTag && showProductAttributes && (
            <Tooltip
              text={t('BoostedListings.BoostedTooltip')}
              maximumWidth={240}
              initialPlacement="bottom"
            >
              <Text type="caption2" className={styles.boostedTagText}>
                <FlashBoldIcon
                  size={12}
                  desc={BOOSTED_TEXT_ANY_LOCALE}
                  className={styles.boostedTagIcon}
                />
              </Text>
            </Tooltip>
          )}
        </>
      )}
    />
  );
}
