import React, { useEffect } from 'react';
import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';

import { ShopProductCard } from '@/app/[locale]/[username]/(shop)/components/ProductCard';
import { ShopProfileSection } from '@/modules/activityTracker/constants';
import { ListProduct } from '@/modules/product/types';
import { buildProductImageProps } from '@/modules/pictures/helpers';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';
import { useActiveShopTab } from '@/modules/shop/hooks/useActiveShopTab';
import { ShopByUsernameResponse } from '@/modules/shop/types';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

type Props = {
  product: ListProduct;
  isRearrangeModeActive?: boolean;
  seller?: ShopByUsernameResponse;
  likeButton?: { isVisible: boolean; isLiked: boolean };
};

export function ProductListCard({
  product,
  seller,
  isRearrangeModeActive,
  likeButton,
}: Props) {
  const { tab } = useActiveShopTab();
  const isViewingOwnShop = useIsViewingOwnShop();
  const [{ web_6367_shop_second_image_hover }, bucket] = useExperiments(
    ['web_6367_shop_second_image_hover'],
    { deferred: true }
  );
  const { isMinMedium } = useResponsive();
  const isShowSecondImageOnHoverVariant =
    web_6367_shop_second_image_hover === VARIANT_IDENTIFIER && isMinMedium;

  useEffect(() => {
    if (isMinMedium) {
      bucket();
    }
  }, [isMinMedium]);

  function getAdditionalShopProductCardProps() {
    const hasMoreThanOnePicture =
      product.pictures && product.pictures.length > 1;
    const showSecondImageOnHover =
      hasMoreThanOnePicture && isShowSecondImageOnHoverVariant;

    if (showSecondImageOnHover) {
      return {
        HoverOverlay: () => (
          <LazyLoadImage
            {...buildProductImageProps({
              preview: product.pictures?.[1] ?? {},
              sellerUserName: product.sellerUserName,
            })}
          />
        ),
      };
    }

    return undefined;
  }

  return (
    <ShopProductCard
      id={product.id}
      isRearrangeModeActive={isRearrangeModeActive}
      status={product.status}
      preview={product.preview}
      price={product.price}
      slug={product.slug}
      showBoostedTag={
        product.isBoosted && tab === ShopProfileSection.Shop && isViewingOwnShop
      }
      sellerUserName={
        [
          ShopProfileSection.Shop,
          ShopProfileSection.Selling,
          ShopProfileSection.Sold,
        ].includes(tab)
          ? seller?.username
          : undefined
      }
      likeButton={likeButton}
      {...(!isRearrangeModeActive && getAdditionalShopProductCardProps())}
    />
  );
}
