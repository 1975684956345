import React, { useEffect } from 'react';
import clsx from 'clsx';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';

import { ShopErrorMessage } from '../ErrorMessage';

import styles from './styles.module.css';

import { InfiniteScroll } from '@/components/InfiniteScroll';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants.ts';
import { useActiveShopTab } from '@/modules/shop/hooks/useActiveShopTab';
import { useShopByUsername } from '@/modules/shop/hooks/useShopByUsername';
import { ListProduct } from '@/modules/product/types';
import { ProductListCard } from '@/app/[locale]/[username]/(shop)/components/ProductList/ProductListCard';
import { RearrangeProductList } from '@/app/[locale]/[username]/(shop)/components/ProductList/RearrangeProductList';

type Props = {
  EmptyState: React.FC;
  hasMore?: boolean;
  isError: boolean;
  isLoading: boolean;
  onEndReached: () => void;
  isFetchingNextPage: boolean;
  products?: ListProduct[];
  className?: string;
  isRearrangeDisabled?: boolean;
};

export function ShopProductsList({
  EmptyState,
  hasMore,
  isError,
  isLoading,
  onEndReached,
  isFetchingNextPage,
  products,
  className,
  isRearrangeDisabled = false,
}: Props) {
  const [_, currentUser] = useCurrentUser();
  const userId = currentUser?.id;
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId,
  });
  const { data } = useShopByUsername();
  const seller = data?.data;
  const { tab } = useActiveShopTab();

  function handleEndReached() {
    sendActivityTrackerEvent(ActivityTrackerEventType.WEB_LOAD_MORE_ACTION, {
      sellerId: seller?.id,
      page: tab,
      schemaVersion: '1.0',
    });
    onEndReached();
  }

  useEffect(() => {
    sendActivityTrackerEvent(ActivityTrackerEventType.USER_PROFILE_VIEW, {
      userId: seller?.id,
      profileSection: tab,
      schemaVersion: '3.0',
    });
  }, [seller?.id, tab]);

  // i.e. fetching the first page of paginated results
  if (isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <LoadingBall />
      </div>
    );
  }

  if (isError) {
    return <ShopErrorMessage />;
  }

  if (!products || !products.length) {
    return <EmptyState />;
  }

  return (
    <div className={clsx(styles.container, className)}>
      <InfiniteScroll
        onEndReached={handleEndReached}
        hasMore={hasMore}
        loading={isFetchingNextPage}
        LoadingComponent={<LoadingBall />}
      >
        <ol className={styles.productGrid}>
          {isRearrangeDisabled ? (
            products.map((product) => (
              <li key={product.id} className={styles.listItem}>
                <ProductListCard product={product} seller={seller} />
              </li>
            ))
          ) : (
            <RearrangeProductList initialProducts={products} seller={seller} />
          )}
        </ol>
      </InfiniteScroll>
    </div>
  );
}
