import { useActiveShopTab } from './useActiveShopTab';
import { useIsViewingOwnShop } from './useIsViewingOwnShop';

import { ShopProfileSection } from '@/modules/activityTracker/constants';
import { MAX_REPOP_RECEIPTS } from '@/modules/shop/constants';
import { useRepopProducts } from '@/modules/receipts/hooks/useRepopProducts';

export const repopSupportedTabs = [
  ShopProfileSection.Shop,
  ShopProfileSection.Selling,
  ShopProfileSection.Sold,
];

export function useRepopOnEmptyShop() {
  const { tab } = useActiveShopTab();
  const isViewingOwnShop = useIsViewingOwnShop();

  const isRepopSupportedTab = repopSupportedTabs.includes(tab);
  const isRepopModuleVisible = isViewingOwnShop && isRepopSupportedTab;
  const repopProducts = useRepopProducts({ enabled: isRepopModuleVisible });
  const products = repopProducts.slice(0, MAX_REPOP_RECEIPTS);
  return {
    products,
    showProducts: isRepopModuleVisible,
  };
}
