import { differenceInDays } from 'date-fns';

import {
  ListReceipt,
  ListReceiptShippingProviderType,
  PaymentProvider,
  Receipt,
  ReceiptStatus,
  ReceiptType,
  ShippingAddress,
  ShippingStatus,
} from '@/modules/receipts/types';
import { ListParcel } from '@/modules/parcels/types';
import { isListDepopShipping } from '@/modules/parcels/helpers';
import { getCurrencySymbolFromCurrencyCode } from '@/modules/currencies/helpers';
import { CurrencyCode } from '@/modules/currencies/constants';

function getListReceiptShippingProviders(
  parcels: ListParcel.AnyParcel[]
): ListReceipt.ShippingProvider[] {
  return parcels.map((parcel) => {
    const { parcelId, parcelStatus } = parcel;
    return {
      parcelId,
      parcelStatus,
      ...(isListDepopShipping(parcel)
        ? {
            type: ListReceiptShippingProviderType.Depop,
            providerName: parcel.providerName,
          }
        : {
            type: ListReceiptShippingProviderType.Manual,
            providerName: parcel.shippingCarrierName,
          }),
    };
  });
}

function getDepopShipping(shippingProviders: ListReceipt.ShippingProvider[]) {
  return shippingProviders.find(
    (shippingProvider) =>
      shippingProvider.type === ListReceiptShippingProviderType.Depop
  );
}

function getManualShipping(shippingProviders: ListReceipt.ShippingProvider[]) {
  return shippingProviders.find(
    (shippingProvider) =>
      shippingProvider.type === ListReceiptShippingProviderType.Manual
  );
}

export function formatShippingAddress(location: ShippingAddress) {
  const { name, address, address2, city, state, postcode, country } =
    location || {};
  const addressParts = [name, address, address2, city, state, postcode];
  let formattedAddress = '';

  addressParts.map((addressPart) => {
    if (addressPart) {
      formattedAddress += `${addressPart}\n`;
    }
  });

  if (country) {
    formattedAddress += country;
  }

  return formattedAddress;
}

/**
 * There is a requirement to show receipt cancelled for seller one day before it actually gets cancelled
 * For this we are checking actual cancellation and then checking deadline for cancellation for AwaitingShipping status,
 * in case it is less than a day left - show item as cancelled
 *
 * For buyer we are showing actual status
 *
 */
export function isReceiptAutoCancelled({
  receipt,
  type,
  isRefunded,
}: {
  receipt: Receipt.Receipt | ListReceipt.TransformedReceipt;
  type?: ReceiptType;
  isRefunded: boolean;
}): boolean {
  if (receipt.cancellationDetails?.reason === 'Auto') {
    return true;
  }

  if (isRefunded) {
    return false;
  }

  const shipmentDeadline = receipt.parcels?.[0]?.shippingDeadline;
  const isAwaitingShipping =
    'receiptStatus' in receipt
      ? receipt.receiptStatus === ReceiptStatus.AwaitingShipping
      : receipt.shippingStatus === ShippingStatus.AwaitingShipping;

  if (shipmentDeadline && isAwaitingShipping && type === ReceiptType.Seller) {
    const deadlineDate = new Date(shipmentDeadline);
    const now = new Date();

    return differenceInDays(deadlineDate, now) < 1;
  }

  return false;
}

function getPaymentInfo(
  currency: CurrencyCode,
  buyerPaymentProvider: PaymentProvider,
  buyerPaidAmount: string,
  sellerPaidAmount?: string
) {
  const localCurrency = getCurrencySymbolFromCurrencyCode(currency);

  return {
    buyerPaidAmountPlain: buyerPaidAmount,
    buyerPaidAmount: `${localCurrency}${buyerPaidAmount}`,
    ...(sellerPaidAmount && {
      sellerPaidAmount: `${localCurrency}${sellerPaidAmount}`,
    }),
    provider: buyerPaymentProvider,
  };
}

export function transformListReceiptData(
  receipt: ListReceipt.Receipt
): ListReceipt.TransformedReceipt {
  const {
    purchaseId,
    buyerPaidAmount,
    items,
    shippingStatus,
    soldTimestamp,
    buyerPaymentProvider,
    parcels,
    buyer,
    seller,
    sellerPaidAmount,
    shippingAddress,
    currency,
    hasBoostingFee,
    isBundle,
    lineItems,
    cancellationDetails,
    buyerRefundAmount,
  } = receipt;
  const shippingProviders = getListReceiptShippingProviders(parcels);
  const depopShipping = getDepopShipping(shippingProviders);
  const manualShipping = getManualShipping(shippingProviders);

  return {
    purchaseId,
    parcels,
    soldTimestamp,
    shippingStatus,
    shippingProviders,
    depopShipping,
    manualShipping,
    hasBoostingFee,
    isBundle,
    lineItems,
    urls: items.map((item) => item.pictureUrl),
    productIds: items.map((item) => item.productId),
    itemCount: items.length,
    buyerInfo: {
      id: buyer.id,
      username: buyer.username,
      location: {
        city: shippingAddress?.city || '',
        postcode: shippingAddress?.postcode?.toUpperCase() || '',
        formattedAddress: shippingAddress
          ? formatShippingAddress(shippingAddress)
          : '',
      },
    },
    sellerInfo: {
      id: seller.id,
      username: seller.username,
    },
    paymentInfo: getPaymentInfo(
      currency,
      buyerPaymentProvider,
      buyerPaidAmount,
      sellerPaidAmount
    ),
    cancellationDetails,
    buyerRefundAmount,
  };
}

export function getHighLevelShippingStatus(shippingStatus: ReceiptStatus) {
  const successShippedStatus = new Set([
    'shipped',
    'returned',
    'failed',
    'delivered',
    'inTransit',
  ]);

  if (successShippedStatus.has(shippingStatus)) {
    return ShippingStatus.Shipped;
  }

  return ShippingStatus.AwaitingShipping;
}

export function getPictureUrls(items: Array<ListReceipt.Item | Receipt.Item>) {
  return items.map((item) => item.pictureUrl);
}

export function getBuyerInfo({
  buyerId,
  username,
  location,
}: {
  buyerId: number;
  username: string;
  location: ShippingAddress;
}) {
  return {
    id: buyerId,
    username,
    location: {
      city: location?.city || '',
      postcode: location?.postcode?.toUpperCase() || '',
      formattedAddress: location ? formatShippingAddress(location) : '',
    },
  };
}
