import clsx from 'clsx';
import React from 'react';
import { useTranslations } from 'next-intl';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { Button } from '@depop/web-ui-kit/Button';
import { TshirtAddIcon } from '@depop/web-ui-kit/Icons/TshirtAddIcon';

import styles from './styles.module.css';

import { ListReceipt } from '@/modules/receipts/types.ts';
import { ExtendedLink } from '@/components/ExtendedLink';
import { RepopTile } from '@/components/RepopTile';

type Props = {
  products: ListReceipt.TransformedReceipt[];
};

export function RepopEmptyState({ products }: Props) {
  const tShop = useTranslations('shop');
  const tSellerHub = useTranslations('sellerhub');

  const hasRepopProducts = products.length > 0;

  return (
    <div
      className={clsx(styles.container, {
        [styles['container-repop']]: hasRepopProducts,
      })}
    >
      <TshirtAddIcon size={34} />
      <Text className={styles.iconText}>{tShop('EmptyState.NoProducts')}</Text>
      <div className={styles.ctaButtonWrapper}>
        <ExtendedLink href="/products/create?cta=ShopEmptyState.NewListing">
          <Button size="md" as="span">
            {tSellerHub('Layout.Button.ListAnItem')}
          </Button>
        </ExtendedLink>
      </div>
      {hasRepopProducts && (
        <>
          <H2 className={styles.title}>{tShop('Repop.Title')}</H2>
          <Text className={styles.description}>
            {tShop('Repop.Description')}
          </Text>
          <div className={styles.receiptsWrapper}>
            {products.map((product) => (
              <RepopTile key={product.purchaseId} {...product} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
