import React from 'react';
import { useTranslations } from 'next-intl';
import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { Button } from '@depop/web-ui-kit/Button';

import styles from './styles.module.css';

import { ListReceipt } from '@/modules/receipts/types';
import { useLegacyProductById } from '@/modules/product/hooks/useLegacyProductById';
import { ExtendedLink } from '@/components/ExtendedLink';

type Props = ListReceipt.TransformedReceipt;

export function RepopTile({
  paymentInfo,
  lineItems,
  purchaseId,
  urls,
  productIds,
}: Props) {
  const t = useTranslations('shop');
  const purchaseItemId = lineItems?.[0]?.id;
  const { data: product } = useLegacyProductById(productIds[0].toString());

  function getCategoryBrandText() {
    const brandName = product?.brandName;
    const categoryName = product?.categoryName;

    if (categoryName && brandName) {
      return `${categoryName}, ${brandName}`;
    }
    return categoryName || brandName || '';
  }

  return (
    <div className={styles.container}>
      <LazyLoadImage src={urls[0]} alt="" />
      <div className={styles.textWrapper}>
        {product && (
          <Text className={styles.categoryBrandText}>
            {getCategoryBrandText()}
          </Text>
        )}
        <Text className={styles.purchasedText} bold type="caption2">
          {`${t('Purchased')}: ${paymentInfo.buyerPaidAmount}`}
        </Text>
      </div>
      <ExtendedLink
        href={`/products/create?pid=${purchaseId}&piid=${purchaseItemId}&cta=ShopEmptyState.Repop`}
      >
        <Button as="span" size="md">
          Repop
        </Button>
      </ExtendedLink>
    </div>
  );
}
