import {
  GetReceiptsResponse,
  Receipt,
  Role,
  SoldItemsShippingStatus,
} from './types';

import {
  RECEIPT_BY_ID_ENDPOINT,
  RECEIPTS_ENDPOINT,
} from '@/constants/endpoints';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';

type FetchReceiptsArgs = {
  shippingStatus: SoldItemsShippingStatus;
  cursor: string | null;
  role: Role;
  limit?: number;
};

const RECEIPT_COUNT_PER_PAGE = 12;

export function getReceipts({
  shippingStatus,
  cursor,
  role,
  limit = RECEIPT_COUNT_PER_PAGE,
}: FetchReceiptsArgs): Promise<AxiosCompatibleResponse<GetReceiptsResponse>> {
  return http.get(RECEIPTS_ENDPOINT, {
    params: {
      role,
      limit,
      cursor,
      shippingStatus,
    },
    withAuth: true,
  });
}

type GetReceiptArgs = {
  purchaseId: number;
  country: string;
  language: string;
  role?: Role;
};

export function getReceipt({
  purchaseId,
  country,
  language,
  role,
}: GetReceiptArgs): Promise<AxiosCompatibleResponse<Receipt.Receipt>> {
  return http.get(
    RECEIPT_BY_ID_ENDPOINT.replace(':purchaseId', purchaseId.toString()),
    {
      params: {
        country,
        lang: language,
        role,
      },
      withAuth: true,
    }
  );
}
