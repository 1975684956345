import { useQuery } from '@tanstack/react-query';

import { getReceipts } from '../api';
import { Role, SoldItemsShippingStatus } from '../types';
import { transformListReceiptData } from '../helpers';

import { RQ_REPOP_PRODUCTS } from '@/modules/ReactQuery/cacheKeys';

export function useRepopProducts({
  enabled,
  limit = 12,
}: {
  enabled: boolean;
  limit?: number;
}) {
  const { data: receiptData } = useQuery({
    queryKey: [RQ_REPOP_PRODUCTS],
    queryFn: () =>
      getReceipts({
        shippingStatus: SoldItemsShippingStatus.InTransit,
        cursor: null,
        limit,
        role: Role.Buyer,
      }),
    enabled,
  });
  const products = receiptData?.data?.receipts || [];
  const filteredProducts = products.filter(
    (product) => !product.isBundle && product.lineItems?.[0]?.repopAvailable
  );

  return filteredProducts.map(transformListReceiptData);
}
