import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { H3 } from '@depop/web-ui-kit/Typography/H3';

import styles from './styles.module.css';

export function ShopErrorMessage() {
  const t = useTranslations('common');

  return (
    <div className={styles.container}>
      <H3 className={styles.title} as="p">
        {t('500.Message')}
      </H3>
      <Text>{t('PleaseTryAgain')}</Text>
    </div>
  );
}
